"use strict";

document.addEventListener("DOMContentLoaded", function() {

    AOS.init();

    const sendedModal = MicroModal.init({
        openTrigger: "data-custom-open",
        disableScroll: true,
        disableFocus: true,
        awaitCloseAnimation: true
    });

    const imgModal = MicroModal.init({
        openTrigger: "data-img-open",
        disableScroll: true,
        disableFocus: true,
        awaitCloseAnimation: true,
        onShow: function(modal, item) {

            modal.querySelector("img").src = item.dataset.src;
        },
    });

    // imgItems.forEach(item => {

    //     item.addEventListener("click", elem => {
    //         imgSource = "";
    //         imgSource = elem.target.src;
    //     });
    // });

    // (function() {
    //     const inputsPhone = document.querySelectorAll("input.js-phone");
    //     const maskOptions = {
    //         mask: '+{7} (000) 000-00-00'
    //     };

    //     inputsPhone.forEach((elem) => {
    //         IMask(elem, maskOptions)
    //     });
    // })();

    (function() {

        const titlePage = document.querySelector(".js-title-page");
        const descPage = document.querySelector(".js-desc-page");

        setTimeout(() => {
            titlePage.classList.add("show");
            descPage.classList.add("show");
        }, 100);
    })();

    // open Menu function
    (function() {
        const btnMenuOpen = document.querySelector(".js-btn-menu");

        const menu = document.querySelector(".menu-wrap");
        const body = document.querySelector("body");

        btnMenuOpen.addEventListener("click", () => {

            menu.classList.toggle("open");
            body.classList.toggle("no-scroll");
        });
    })();

    // button scroll up function
    (function() {
        const scrollToTopButton = document.querySelector(".js-btn-up");

        function scrollToTop() {

            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }

        function toggleButtonVisibility() {

            if (window.scrollY > 300) {

                scrollToTopButton.classList.add("show");
            } else {

                scrollToTopButton.classList.remove("show");
            }
        }

        window.addEventListener("scroll", toggleButtonVisibility);

        scrollToTopButton.addEventListener("click", scrollToTop);
    })();

    // scroll to element
    (function() {

        function smoothScrollTo(targetId) {

        const target = document.getElementById(targetId);

            if (!target) return;

            target.scrollIntoView({
                behavior: 'smooth'
            });
        }

        const scrollLinks = document.querySelectorAll("a[href^='#']");
        const scrollButtons = document.querySelectorAll("button[data-scroll-to]");

        const menu = document.querySelector(".menu-wrap");
        const body = document.querySelector("body");

        scrollLinks.forEach(link => {

            link.addEventListener("click", (event) => {
                event.preventDefault();

                menu.classList.remove("open");
                body.classList.remove("no-scroll");

                const targetId = link.getAttribute("href").substring(1);
                smoothScrollTo(targetId);
            });
        });

        scrollButtons.forEach(button => {
            button.addEventListener("click", () => {

                const targetId = button.getAttribute("data-scroll-to");
                smoothScrollTo(targetId);
            });
        });
    })();

    // items show
    (function() {
        const items = document.querySelectorAll('.js-func');
        let index = 0;

        const optionsIndustry = {
            rootMargin: '0px',
            threshold: [0.9],
        }

        const observerIndustry = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    showItemsWithDelay();
                    observer.unobserve(entry.target);
                }
            });
        }

        if (items.length > 0) {

            const intersectionObserver = new IntersectionObserver(observerIndustry, optionsIndustry);

            intersectionObserver.observe(items[0]);
        }

        function showItemsWithDelay() {
            if (index < items.length) {
                setTimeout(() => {
                    items[index].classList.add('show');
                    index++;
                    showItemsWithDelay();
                }, 200);
            }
        }
    })();

    // title line show
    (function() {
        const items = document.querySelectorAll('.title .line');
        let index = 0;

        const optionsIndustry = {
            rootMargin: '0px',
            threshold: [0.9],
        }

        const observerIndustry = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('show');
                    } else {
                        entry.target.classList.remove('show');
                    }
                }
            });
        }

        if (items.length > 0) {

            const intersectionObserver = new IntersectionObserver(observerIndustry, optionsIndustry);

            items.forEach(element => {
                intersectionObserver.observe(element);
            });
        }

        function showItemsWithDelay() {
            if (index < items.length) {
                setTimeout(() => {
                    items[index].classList.add('show');
                    index++;
                    showItemsWithDelay();
                }, 200);
            }
        }
    })();

});